.loader-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 999;
    background: rgba(255, 255, 255, 0.25);

    &.--top {
        align-items: flex-start;
    }
}
