.change-password-modal {
    .ant-input-password {
        border-radius: 30px;
        height: 40px;
        line-height: 30px;
    }
    .ant-btn {
        width: 100%;
        margin-top: 16px;
    }
}