@import 'shared/style/Variables.scss';

.reset-password {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    min-height: 100%;

    .__header {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 16px;

        background: $ambiant-color;
        background-image: linear-gradient(
            $ambiant-color,
            darken($ambiant-color, 5%)
        );

        .__logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 200px;

            .__icon {
                width: 160px;
            }

            img {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        .__catch-phrase {
            color: white;
            width: 300px;
            font-size: 1.2rem;
            text-align: justify;
        }

        .__more-info-btn {
            width: 300px;
            margin-top: 16px;
            color: white;
            font-size: 1.2rem;
            line-height: 1px;

            &:hover {
                border-color: white;
                background-color: white !important;
                color: $ambiant-color;
            }
            &:active,
            &:focus {
                border-color: white;
            }
        }
    }

    .__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-height: 400px;
        padding: 32px 16px;

        .ant-form {
            width: 400px;

            .ant-input-affix-wrapper {
                border-radius: 40px;
            }
        }
        .ant-btn {
            width: 400px;
            max-width: 400px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .reset-password {
        flex-direction: column;

        .__logo {
            img {
                margin-bottom: 0 !important;
            }
            .__icon {
                height: 60px;
                margin-bottom: 4px !important;
            }
            .__text {
                height: 20px;
            }
        }

        .__header {
            flex: auto;
            max-height: 120px;
            width: 100%;
            .__catch-phrase,
            .__more-info-btn {
                display: none;
            }
        }

        .__content {
            width: 100%;
            height: 100%;
            max-height: 100%;
            padding: 32px 16px 16px 16px;
            justify-content: flex-start;

            .__push-div {
                flex: 1;
            }

            .ant-btn,
            .ant-form {
                width: 100%;
                max-width: 400px;
            }
        }
    }
}
