@import "shared/style/Variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $page-background;

  min-height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
  &:hover {
    transform: scale(1.05, 1.05);
  }
}


@import 'shared/style/antd/_antd.scss';

.button-form {
  text-align: right;
}


h1 {
  text-transform: uppercase;
}

h3 {
  margin-top: 16px;
  color: rgba(#000, 0.65);
  font-size: 14px;
  font-weight: bold;
}