
.markdown {
    h1 {
        font-size: 18px;
        font-weight: bold;
        color: $primary-color;
        text-transform: uppercase;
    }
    h2 {
        font-size: 18px;
    }
}