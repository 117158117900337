@import 'shared/style/Variables.scss';

// Layout

// Variables
$layout-header-background: $header-background;
$layout-header-color: $header-color;
$layout-header-height: 64px;

$layout-header-border: $light-gray;
$layout-content-background: none;
$layout-footer-background: $header-background;
$layout-footer-border: $light-gray;

.ant-layout {
    background: $page-background;
}

.ant-menu-submenu {
    .ant-menu-sub {
        background: $sidebar-sub-menu-background;
    }
    .ant-menu-item-selected {
        background: $sidebar-selected-item-background !important;
    }
}

.desktop-layout {
    .ant-layout-content {
        margin-left: 200px;
        padding: 16px 16px 0 16px;
    }
    .ant-layout-header {
        background: white;
        box-shadow: $shadow-0;

        .ant-avatar {
            background: $primary-color;
            float: right;
            margin-top: 12px;
            cursor: pointer;
        }
    }
    &.--collapsed {
        > .ant-layout-sider {
            max-width: 60px !important;
            min-width: 60px !important;
            width: 60px !important;

            .ant-menu-inline-collapsed {
                width: 60px;

                .ant-menu-item {
                    padding: 0 22px !important;
                }
            }

            .ant-layout-sider-trigger {
                width: 60px !important;
            }
        }
        > .ant-layout-content {
            margin-left: 60px;
        }
    }
}
.mobile-layout {
    > .ant-layout-content {
        padding: 64px 8px 64px 8px;
    }
    > .__header {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 99;
        background: $sidebar-background;
        padding: 16px;
        .__logo {
            display: flex;
            justify-content: center;
            align-items: center;

            .__icon {
                position: absolute;
                left: 16px;
                height: 40px;
                margin-right: 16px;
            }
            .__text {
                height: 25px;
            }
        }
        .__logout-btn {
            position: absolute;
            top: 8px;
            right: 16px;
            background: none;
            border-color: #ffffff;
            color: #ffffff;
        }
    }
    > .__menu {
        display: flex;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
        background: $sidebar-background;
        z-index: 3;

        .ant-menu-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin: 0;
            height: 50px;
            line-height: 50px;
            padding: 0 !important;

            &:hover,
            &:focus,
            &.ant-menu-item-active {
                color: white;
            }
            &:active,
            &.ant-menu-item-selected {
                color: white;
                background: $sidebar-selected-item-background;
                &:after {
                    display: none;
                }
            }

            &:active {
                background: darken($sidebar-background, 5%);
            }

            .anticon {
                margin: 0;
                font-size: 18px;
            }
            .__label {
                line-height: 20px;
                font-size: 12px;
                text-align: center;
            }
        }
    }
}

#root > .ant-layout {
    > .ant-layout-sider {
        position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 101;
        background: $sidebar-background;
        background-image: linear-gradient(
            $sidebar-background,
            darken($sidebar-background, 5%)
        );

        .ant-menu-dark {
            background: none;
        }

        .ant-menu-sub {
            background: $sidebar-sub-menu-background;
        }

        .ant-menu-item-selected {
            background: $sidebar-selected-item-background;
        }
        .ant-layout-sider-trigger {
            background: $sidebar-footer-background;
        }

        .ant-menu-item:hover,
        .ant-menu-item-active,
        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: $white;
        }

        .logo {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            height: $layout-header-height;
            line-height: $layout-header-height;

            background: $layout-header-background;

            .__icon {
                width: 40px;
                transition: width 0.2s;
            }

            .__text {
                transition: width 0.2s;
                margin-left: 8px;
                width: 100px;
            }
        }

        .ant-menu {
            margin-bottom: 50px;
        }

        .ant-badge {
            right: 8px;
            position: absolute;
            top: 10px;
            .ant-badge-count {
                background: $primary-color;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .scrollarea {
            height: 100vh;
        }

        &.ant-layout-sider-collapsed {
            .__text {
                width: 0;
                margin: 0;
                visibility: hidden;
            }
        }
    }

    > .ant-layout > div {
        transition: 0.2s padding, 0.2s margin;
    }
}

.layout-user-menu {
    .ant-popover-inner-content {
        display: flex;
        flex-direction: column;
        padding: 0;

        button {
            min-width: 200px;
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
        }
        button:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        button:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: none;
        }
    }
}
