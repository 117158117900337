@import "shared/style/Variables.scss";
.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 5vw 0 5vw;
    height: 100vh;

    text-align: center;

    h1 {
        color: $ambiant-color;
        font-weight: bold;
    }
    .__action {
        margin-bottom: 2rem;
    }
    .__details {
        padding-top: 1rem;
        width: 100%;
        max-width: 600px;

        .__message {
            margin-bottom: .5rem;
            font-size: 16px;
        }
        .__stack {
            font-size: 12px;
            white-space: pre;
        }
    }

    .ant-btn {
        width: 243px;
    }
}